<template>
  <div>
    <data-table-component
      v-models:select="perPage"
      v-models:pagina="page"
      :headers="headers"
      :items="shareholders"
      @paginar="paginar"
      :total_registros="totalRows"
    >
      <template v-slot:[`item.type`]="{ item }">
        {{ item.documentos[0].tipo_documento ?? "" }}
      </template>

      <template v-slot:[`item.doc`]="{ item }">
        {{ item.documentos[0].numero_documento ?? "" }}
      </template>

      <template v-slot:[`item.legal`]="{ item }">
        <v-icon v-if="item.agente_legal == true" color="btnGreen"
          >mdi-check-circle-outline</v-icon
        >
        <v-icon v-else color="btnRed">mdi-close-circle-outline</v-icon>
      </template>
      <template v-slot:[`item.percent`]="{ item }">
        {{ item.porcentaje_participacion }} %
      </template>
      <template v-slot:[`item.name`]="{ item }">
        {{ item.primer_nombre?.toUpperCase() }}
        {{ item.segundo_nombre?.toUpperCase() }}
        {{ item.tercer_nombre?.toUpperCase() }}
        {{ item.primer_apellido?.toUpperCase() }}
        {{ item.segundo_apellido?.toUpperCase() }}
        {{ item.apellido_casada?.toUpperCase() }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip right v-if="item.id_tipo_persona == 2">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" @click="goToAsociados(item)">
              mdi-plus
            </v-icon>
          </template>
          <span> Agregar accionista </span>
        </v-tooltip>
        <v-tooltip right v-if="haveRole('ROLE_PROVEEDOR')">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              @click="onShowDeleteDialog(item.id)"
            >
              mdi-delete
            </v-icon>
          </template>
          <span> Eliminar </span>
        </v-tooltip>
        <!-- Detalle accionista -->
        <v-tooltip right v-if="haveRole('ROLE_TECNICO_DIRECCION_COMPRAS')">
          <template v-slot:activator="{ on, attrs }">
            <v-btn 
              v-bind="attrs"
              v-on="on" 
              icon 
              :loading="cargandoAccionista"
              @click="mostrarDetalleAccionista(item)"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
          <span> Ver accionista </span>
        </v-tooltip>
      </template>
      <!-- <template v-slot:[`footer`]>
        <app-pagination-component
          :totalElements="totalRows"
          @cantidadPage="paginate"
          @changePage="paginate"
        />
      </template> -->
    </data-table-component>
    <ConfirmationDialog
      :show="showDeleteDialog"
      title="¿Desea remover el accionista seleccionado?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      @close="showDeleteDialog = false"
      @confirm="onConfirmDialog"
    />
    <ModalDetalleAccionistaComponent 
      :is-open="modalDetalleAccionistaAbierta"
      :cargandoAccionista="cargandoAccionista"
      :informacion-accionista="informacionAccionista"
      @on-visibility-change="manejarVisibilidadModalDetalleAccionista"
    />
    <!-- <v-btn class="mt-8" outlined @click="$router.back()"> Regresar </v-btn> -->
  </div>
</template>

<script>
import ConfirmationDialog from "@/components/ConfirmationDialogComponent.vue";
import { mapActions, mapState } from "vuex";
import AppPaginationComponent from "../../../components/AppPaginationComponent.vue";
import DataTableComponent from "../../../components/DataTableComponent.vue";
import ModalDetalleAccionistaComponent from './ModalDetalleAcccionistaComponent.vue';

export default {
  name: "TablaAccionistasComponent",
  components: {
    ConfirmationDialog,
    AppPaginationComponent,
    DataTableComponent,
    ModalDetalleAccionistaComponent,
  },
  data: () => ({
    id_shareholder_to_delete: 0,
    showDeleteDialog: false,
    headers: [
      {
        text: "Tipo de persona",
        align: "center",
        value: "tipo_contribuyente.nombre",
      },
      {
        text: "Tipo de documento",
        align: "center",
        value: "type",
      },
      { text: "Número de documento", align: "center", value: "doc" },
      { text: "Identificación", align: "center", value: "name" },
      {
        text: "Participación(%)",
        align: "center",
        value: "percent",
        sortable: false,
      },
      {
        text: "Representante legal",
        align: "center",
        value: "legal",
        sortable: false,
      },
      { text: "Acciones", align: "center", value: "actions", sortable: false },
    ],
    // ver detalle accionista
    cargandoAccionista: false,
    accionistaSeleccionado: null,
    informacionAccionista: null,
    modalDetalleAccionistaAbierta: false,
    pagination: {
      page: 1,
      perPage: 10,
    }
  }),
  methods: {
    ...mapActions("shareholders", [
      "getShareholders",
      "deleteShareholder",
      "paginate",
    ]),
    deleteOption(item) {
      this.shareholders = this.shareholders.filter(
        (person) => person.doc !== item.doc
      );
    },
    onShowDeleteDialog(id) {
      this.id_shareholder_to_delete = id;
      this.showDeleteDialog = true;
    },
    async onConfirmDialog() {
      this.showDeleteDialog = false;
      const result = await this.deleteShareholder({
        id: this.id_shareholder_to_delete,
      });

      if (result) {
        this.temporalAlert({
          show: true,
          message: "El accionista ha sido eliminado",
          type: "success",
        });
      }
    },
    async mostrarDetalleAccionista(accionista) {
      this.accionistaSeleccionado = accionista;

      try {
        this.cargandoAccionista = true;
        const { data, status } = await this.services.Proveedores.cargarAccionista(accionista.id);

        if (status === 200) {
          this.informacionAccionista = data;
          this.modalDetalleAccionistaAbierta = true;
        }
      } catch (error) {
      } finally {
        this.cargandoAccionista = false;
      }
    },
    manejarVisibilidadModalDetalleAccionista(visible) {
      this.modalDetalleAccionistaAbierta = visible;
    },
    goToAsociados(item) {
      this.$router.push(
        `/accionistas/asociados/${item.id}/${
          this.$route.params?.idProveedor || ""
        }`
      );
    },
    async paginar({pagina, cantidad_por_pagina}) {
      this.pagination.page = pagina;
      this.pagination.perPage = cantidad_por_pagina;
      await this.getShareholders({
        id_sociedad: this.$route.params?.id_accionista,
        id_proveedor: this.$route.params?.idProveedor,
        page: this.pagination.page,
        perPage: this.pagination.perPage,
      });
    }
  },
  computed: {
    ...mapState("shareholders", [
      "shareholders",
      "page",
      "perPage",
      "totalRows",
    ]),
  },
  async created() {
    if (this.$route.params.id_accionista) {
      await this.getShareholders({
        id_sociedad: this.$route.params?.id_accionista,
        id_proveedor: this.$route.params?.idProveedor,
      });
    } else {
      await this.getShareholders({
        id_proveedor: this.$route.params?.idProveedor,
      });
    }
  },
};
</script>
