<template>
  <section>
    <!-- 
        Selector del tipo de persona, el formulario de registro cambiara en 
        base a la opción seleccionada
    -->
    <v-row class="mt-5">
      <v-col cols="6">
        <v-select
          v-model="idTipoPersona"
          :items="tiposPersona"
          label="Tipo de persona"
          outlined
          item-text="nombre"
          item-value="id"
          :rules="[v => !!v || 'Este campo es requerido']"
        />
      </v-col>
    </v-row>

    <!-- Formulario para registrar accionistas como persona natural -->
    <agregar-accionista-natural-component
      v-if="idTipoPersona == 1"
    ></agregar-accionista-natural-component>

    <!-- Formulario para registrar accionistas como persona jurídica -->
    <agregar-accionista-juridico-component
      v-if="idTipoPersona == 2"
    ></agregar-accionista-juridico-component>
  </section>
</template>
<script>
// Importación de componentes
import AgregarAccionistaNaturalComponent from "./AgregarAccionistaNaturalComponent.vue";
import AgregarAccionistaJuridicoComponent from "./AgregarAccionistaJuridicoComponent.vue";
export default {
  name: "AgregarAccionistaV2Component",
  components: {
    AgregarAccionistaNaturalComponent,
    AgregarAccionistaJuridicoComponent,
  },
  data: () => ({
    idTipoPersona: 1,
    tiposPersona: [
      { nombre: "Persona Natural", id: 1 },
      { nombre: "Persona Jurídica", id: 2 },
    ],
  }),
  methods: {},
};
</script>
<style lang=""></style>
