<template>
    <v-dialog v-model="internalOpen" max-width="700">
        <div style="background-color: #FFFFFF; padding: 0 1em 1em; position: relative;">
            <div class="py-4" style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
                <v-btn 
                    :loading="cargandoAccionista"
                    style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                    class="px-4 py-4"
                    @click.stop="cerrarModal" 
                    text
                    rounded
                >
                    <v-icon>mdi-window-close</v-icon>
                </v-btn>
            </div>
            <div class="px-1 mt-1">
                <template v-if="informacionAccionista">
                    <v-card elevation="2">
                        <v-card-title>Generalidades</v-card-title>
                        <v-card-text>
                            <p class="mb-4">
                                <span class="font-weight-bold">Nombre completo:</span> <br />
                                {{ nombreCompleto }}
                            </p>
                            <p class="mb-4">
                                <span class="font-weight-bold">Participación (%):</span> <br />
                                {{ informacionAccionista.porcentaje_participacion }}
                            </p>
                            <p class="mb-4">
                                <span class="font-weight-bold">Tipo de contribuyente:</span> <br />
                                {{ informacionAccionista.tipo_contribuyente.nombre }}
                            </p>
                            <p class="mb-4">
                                <span class="font-weight-bold">Nombre comercial:</span> <br />
                                {{ informacionAccionista.proveedor.nombre_comercial }}
                            </p>
                            <p class="mb-4">
                                <span class="font-weight-bold">Teléfono:</span> <br />
                                {{ informacionAccionista.persona.telefono }}
                            </p>
                        </v-card-text>
                    </v-card>
                    <v-card elevation="2" class="mt-4">
                        <v-card-title>Documentos</v-card-title>
                        <v-card-text>
                            <ul class="d-flex flex-column pl-0" style="gap: 16px; list-style: none;">
                                <li v-for="documento in informacionAccionista.persona.documentos">
                                    <v-icon>mdi-file-document</v-icon>
                                    <span class="d-inline-block ml-2">
                                        <b>{{ documento.numero_documento }}</b>
                                        - {{ documento.tipo }}
                                    </span>
                                </li>
                            </ul>
                        </v-card-text>
                    </v-card>
                </template>
                <div class="mt-4 text-center" style="position: sticky; bottom: 16px; background-color: #FFF;">
                    <v-btn @click.stop="cerrarModal" class="d-inline-block mx-auto" color="primary">
                        Cerrar
                    </v-btn>
                </div>
            </div>
        </div>
    </v-dialog>
</template>
<script>
import { isNil } from 'lodash';
import { obtenerNombreCompletoPersona } from '@/utils/data';

export default {
    name: 'EditarConvocatoria',
    emits: ['on-visibility-change'],
    props: {
        isOpen: { type: Boolean },
        cargandoAccionista: { type: Boolean },
        informacionAccionista: { type: Object },
    }, 
    data: () =>({
            internalOpen: false,
    }),
    methods: {
        cerrarModal() {
            this.internalOpen = false;
        },
    },
    computed: {
        nombreCompleto() {
            if (!this.informacionAccionista) return '';

            return obtenerNombreCompletoPersona(this.informacionAccionista.persona);
        },
    },
    watch: {
        isOpen(value) {
            if (isNil(value)) return;

            this.internalOpen = value;
        },
        internalOpen(value) {
            this.$emit('on-visibility-change', value);
        },
    },
}
</script>