import { required } from 'vuelidate/lib/validators';
<template>
  <!-- Formulario para registrar personas naturales -->
  <section>
    <v-row>
      <!-- FORMULARIO BASE PARA REGISTRAR ACCIONISTA -->
      <v-col cols="6" lg="6">
        <!-- Selector de tipo de documento -->
        <v-row>
          <v-col cols="12">
            <v-select
              :error-messages="tipoDocumentoError"
              @change="cambiarFormatoDocumento"
              v-model="formulario.id_tipo_documento"
              :items="tiposDocumentos"
              label="Tipo de documento*"
              outlined
              item-text="nombre"
              item-value="id"
            ></v-select>
          </v-col>
        </v-row>

        <!-- Campo para ingresar el número de documento -->
        <v-row>
          <v-col :cols="formulario.id_tipo_documento == 1 ? '8' : '12'">
            <v-text-field
              :error-messages="numeroDocumentoError"
              v-model="formulario.numero_documento"
              label="Número de identificación*"
              outlined
              :placeholder="formatoDocumento"
              :append-icon="duiVerificado ? 'mdi-check-circle' : ''"
            ></v-text-field>
          </v-col>

          <!--
            Si el tipo de documento seleccionado es DUI mostramos el botón para
            verificar el número de DUI ingresado
            -->
          <v-col cols="4" v-if="formulario.id_tipo_documento == 1">
            <v-btn
              :loading="verificarDuiLoading"
              :disabled="verificarDuiLoading || duiVerificado"
              height="55"
              block
              class="px-10 white--text"
              :class="duiVerificado ? 'gray' : 'btnGreen'"
              outlined
              @click="verificarDUI"
            >
              Verificar
            </v-btn>
          </v-col>
        </v-row>

        <!-- Porcentaje de participación -->
        <v-row>
          <v-col cols="12">
            <v-text-field
              :error-messages="porcentajeParticipacionError"
              @keypress="this.filterNumber"
              prepend-inner-icon="mdi-percent-outline"
              v-model="formulario.porcentaje_participacion"
              label="Porcentaje de participación*"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="d-flex mt-n3">
            <p class="black--text mr-5">¿Es representante legal?</p>
            <v-checkbox
              v-model="formulario.agente_legal"
              class="mt-n1"
            ></v-checkbox>
          </v-col>
          <v-col class="d-flex mt-n3" v-if="$route.params.id_accionista">
            <p class="black--text mr-5">¿Es accionista?</p>
            <v-checkbox
              v-model="formulario.es_accionista"
              class="mt-n1"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-col>

      <!-- Formulario para registro manual sin verificación con el RNPN -->
      <v-col
        cols="12"
        lg="6"
        v-if="
          formulario.id_tipo_documento != 1 &&
          formulario.id_tipo_documento != null
        "
      >
        <v-row>
          <!-- Input primer nombre -->
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              label="Primer nombre*"
              outlined
              v-model="formulario.primer_nombre"
              :error-messages="primerNombreError"
            >
            </v-text-field>
          </v-col>

          <!-- Input segundo nombre -->
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              label="Segundo nombre"
              outlined
              v-model="formulario.segundo_nombre"
            >
            </v-text-field>
          </v-col>

          <!-- Input tercer nombre -->
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              label="Tercer nombre"
              outlined
              v-model="formulario.tercer_nombre"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              label="Primer apellido *"
              outlined
              v-model="formulario.primer_apellido"
              :error-messages="primerApellidoError"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              label="Segundo apellido"
              outlined
              v-model="formulario.segundo_apellido"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              label="Apellido de casada"
              outlined
              v-model="formulario.apellido_casada"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6" lg="4">
            <v-select
              outlined
              :items="generos"
              on
              label="Género*"
              item-text="nombre"
              item-value="id"
              v-model="formulario.id_genero"
              :error-messages="generoError"
            >
            </v-select>
          </v-col>
          <v-col cols="12" md="6" lg="8">
            <v-text-field
              label="Dirección*"
              outlined
              v-model="formulario.direccion"
              :error-messages="direccionError"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>

      <!-- Información del DUI validada -->
      <v-col v-if="nombreCompleto != null" cols="12" lg="6">
        <h6 class="btnGreen--text">Nombre del proveedor:</h6>
        <h4>
          {{ nombreCompleto }}
        </h4>
        <br />

        <h6 class="btnGreen--text">DUI:</h6>
        <h4>{{ formulario.numero_documento }}</h4>
        <br />
      </v-col>
    </v-row>

    <!-- Botón para registrar accionista -->
    <v-row>
      <v-col cols="12" lg="6">
        <v-btn
          :loading="guardarAccionistaLoading"
          color="secondary white--text"
          @click="guardarAccionista"
          >Agregar accionista</v-btn
        >
      </v-col>
    </v-row>
  </section>
</template>
<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

const validarFormatoRegex = (formato) => {
  const validaciones = formato.split('|');
  const expresiones = validaciones.map((validacion) => {
    return new RegExp(`^${validacion.replace(/#/ig, '\\d')}$`);
  });

  return (value) => expresiones.some((expresion) => expresion.test(value));
};

export default {
  name: "AgregarAccionistaNaturalComponent",
  data: () => ({
    formulario: {
      id_tipo_persona: 1,
      id_persona: null,
      id_tipo_documento: null,
      numero_documento: null,
      porcentaje_participacion: null,
      agente_legal: false,
      es_accionista: false,

      // Datos para registro manual sin verificación con el RNPN
      primer_nombre: "",
      segundo_nombre: "",
      tercer_nombre: "",
      primer_apellido: "",
      segundo_apellido: "",
      apellido_casada: "",
      id_genero: null,
      direccion: null,
    },

    verificarDuiLoading: false, // Indica si se está verificando el DUI
    duiVerificado: false, // Indica si el DUI es válido según el RNPN
    nombreCompleto: null, // Nombre completo de la persona según el RNPN

    formatoDocumento: "",
    tiposDocumentos: [],

    generos: [],

    guardarAccionistaLoading: false,
  }),
  validations() {
    return ({
      formulario: {
        id_tipo_documento: { required },
        numero_documento: { required, ...this.formatoDocumento && ({ regex: validarFormatoRegex(this.formatoDocumento) }) },
        porcentaje_participacion: { required },
        primer_nombre: {
          requiredIf: requiredIf(function () {
            return this.formulario.id_tipo_documento != 1;
          }),
        },
        primer_apellido: {
          requiredIf: requiredIf(function () {
            return this.formulario.id_tipo_documento != 1;
          }),
        },
        id_genero: {
          requiredIf: requiredIf(function () {
            return this.formulario.id_tipo_documento != 1;
          }),
        },
        direccion: {
          requiredIf: requiredIf(function () {
            return this.formulario.id_tipo_documento != 1;
          }),
        },
      },
    });
  },
  computed: {
    // Computadas para mensajes de validación
    tipoDocumentoError() {
      const errors = [];
      if (!this.$v.formulario.id_tipo_documento.$dirty) return errors;
      !this.$v.formulario.id_tipo_documento.required &&
        errors.push("El tipo de documento es requerido");
      return errors;
    },
    numeroDocumentoError() {
      const errors = [];
      if (!this.$v.formulario.numero_documento.$dirty) return errors;

      !this.$v.formulario.numero_documento.required && errors.push("El número de documento es requerido");

      if (!this.duiVerificado) {
        if (this.formulario.id_tipo_documento == 1) {
          errors.push("Debe verificar el número de DUI");
          return errors;
        }
      }

      if (this.formatoDocumento && !this.$v.formulario.numero_documento.regex) {
        errors.push(`El número de documento debe seguir el formato: ${this.formatoDocumento.split('|').join(' o ')}`);
      }

      return errors;
    },
    porcentajeParticipacionError() {
      const errors = [];
      if (!this.$v.formulario.porcentaje_participacion.$dirty) return errors;
      !this.$v.formulario.porcentaje_participacion.required &&
        errors.push("El porcentaje de participación es requerido");
      return errors;
    },
    primerNombreError() {
      const errors = [];
      if (!this.$v.formulario.primer_nombre.$dirty) return errors;
      !this.$v.formulario.primer_nombre.requiredIf &&
        errors.push("El primer nombre es requerido");
      return errors;
    },
    primerApellidoError() {
      const errors = [];
      if (!this.$v.formulario.primer_apellido.$dirty) return errors;
      !this.$v.formulario.primer_apellido.requiredIf &&
        errors.push("El primer apellido es requerido");
      return errors;
    },
    generoError() {
      const errors = [];
      if (!this.$v.formulario.id_genero.$dirty) return errors;
      !this.$v.formulario.id_genero.requiredIf &&
        errors.push("El género es requerido");
      return errors;
    },
    direccionError() {
      const errors = [];
      if (!this.$v.formulario.direccion.$dirty) return errors;
      !this.$v.formulario.direccion.requiredIf &&
        errors.push("La dirección es requerida");
      return errors;
    },
  },
  methods: {
    ...mapActions("shareholders", ["getShareholders"]),
    /**
     * Obtiene la lista de tipos de documentos para ser mostrados en el selector
     * de tipo de documento
     */
    async obtenerTiposDocumentos() {
      const response = await this.services.Documentos.getDocumentTypeList({
        id_categoria_documento: 1,
      });
      this.tiposDocumentos = response.data;
    },
    /**
     * Obtiene el listado de géneros para ser mostrados en el selector de género
     */
    async obtenerGeneros() {
      const response = await this.services.Generos.getGenerosList();
      if (response.status == 200) {
        this.generos = response.data;
      }
    },
    /**
     * Cambia el formato (mascara) del campo de número de documento en base al
     * tipo de documento seleccionado
     */
    cambiarFormatoDocumento() {
      this.duiVerificado = false;
      this.nombreCompleto = null;

      if (this.formulario.id_tipo_documento != null) {
        // Buscamos en tiposDocumento el tipo de documento con el id almacenado en formulario.id_tipo_documento
        const tipoDocumento = this.tiposDocumentos.find(
          (tipoDocumento) =>
            tipoDocumento.id == this.formulario.id_tipo_documento
        );
        if (tipoDocumento.nombre == "Documento genérico")
          this.formatoDocumento = "";
        else this.formatoDocumento = tipoDocumento.formato;
      } else {
        this.formatoDocumento = "";
      }
    },
    /**
     * Verifica si el número de DUI ingresado es válido
     */
    async verificarDUI() {
      if (this.formulario.numero_documento != null) {
        this.verificarDuiLoading = true;

        const response =
          await this.services.VerificarDocumento.verificarDocumento(
            this.formulario.numero_documento
          );

        if (response.status == 200) {
          const { data } = response;

          this.duiVerificado = true;
          this.formulario.id_persona = data.id;

          const parts = [
            data?.primer_nombre || "",
            data?.segundo_nombre || "",
            data?.primer_apellido || "",
            data?.segundo_apellido || "",
            data?.apellido_casada || "",
          ];

          this.nombreCompleto = parts.filter(Boolean).join(" ");
        }

        this.verificarDuiLoading = false;
      }
    },
    /**
     * Guarda el accionista en la base de datos
     */
    async guardarAccionista() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.guardarAccionistaLoading = true;
        if (this.$route.params.id_accionista) {
          this.formulario.id_sociedad = this.$route.params.id_accionista;
        }

        const response = await this.services.Proveedores.postShareholder(
          this.formulario
        );

        if (response.status == 201) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "Accionista agregado correctamente",
          });

          // Reiniciamos el formulario
          this.formulario = {
            id_persona: null,
            id_tipo_documento: null,
            numero_documento: null,
            porcentaje_participacion: null,
            agente_legal: false,
            primer_nombre: null,
            segundo_nombre: null,
            primer_apellido: null,
            segundo_apellido: null,
            apellido_casada: null,
            id_genero: null,
            direccion: null,
          };

          this.duiVerificado = false;
          this.nombreCompleto = null;
          this.$v.$reset();
          this.getShareholders({
            id_sociedad: this.$route.params?.id_accionista,
          });
        }

        this.guardarAccionistaLoading = false;
      }
    },
  },
  mounted() {
    this.obtenerTiposDocumentos();
    this.obtenerGeneros();
  },
};
</script>
<style lang="scss"></style>
